import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "HTTP Methods and when to use them",
  "description": "HTTP Methods and when to use them",
  "date": "2020-04-11",
  "image": "http-method.jpg",
  "tags": ["http", "fundamentals", "restapi"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`HTTP Methods are actions you would like to perform on the server. They are part of HTTP request.`}</p>
    <ul>
      <li parentName="ul">{`GET`}</li>
      <li parentName="ul">{`POST`}</li>
      <li parentName="ul">{`PUT`}</li>
      <li parentName="ul">{`DELETE`}</li>
      <li parentName="ul">{`HEAD`}</li>
      <li parentName="ul">{`PATCH`}</li>
      <li parentName="ul">{`OPTIONS`}</li>
      <li parentName="ul">{`CONNECT`}</li>
    </ul>
    <h2>{`GET method`}</h2>
    <p>{`This is one of the most common methods used in websites. It is used to request data from a specified resource.
GET requests are considered safe and idempotent as they only read the data from the server and produces the same result regardless of how many times they gets called.`}</p>
    <p>{`GET requests should only be used to get data. Since they can be bookmarked and remain in browser history, it should never be used to deal with any sensitive data.
GET requests can be cached and  have length restrictions.`}</p>
    <p>{`Example: to get a list of shirts or users`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`GET /shirts
GET https://example.com/shirts`}</code></pre></div>
    <h2>{`POST method`}</h2>
    <p>{`POST is used to send data to update/create to a resource. However, POST is `}<em parentName="p">{`non-idempotent`}</em>{`. The data sent to the server is stored at the request body.`}</p>
    <p>{`POST requests are not cached and have no length restrictions on data.`}</p>
    <p>{`Example`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`POST /test/demo_form.php HTTP/1.1
Host: example.com
name1=value1&name2=value2`}</code></pre></div>
    <h2>{`PUT method`}</h2>
    <p>{`PUT is also one of the most common HTTP methods.
It is used to update/create data to a resource. `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT"
      }}>{`PUT requests are idempotent`}</a>{`, which means calling it once or more will produce the same result. `}</p>
    <p>{`Example `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`PUT /blogs/1 HTTP/1.1
Host: example.com
name1=value1`}</code></pre></div>
    <h3>{`When to use PUT and POST`}</h3>
    <p>{`The difference between PUT and POST requests is stated in the `}<a parentName="p" {...{
        "href": ""
      }}>{`RFC 2616 §9.5ff`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`The fundamental difference between the POST and PUT requests is reflected
in the different meaning of the Request-URI. The URI in a POST request identifies
the resource that will handle the enclosed entity.
That resource might be a data-accepting process, a gateway to some other protocol, or a
separate entity that accepts annotations.
In contrast, the URI in a PUT request identifies the entity enclosed with the request -- the user agent knows what URI is intended and the server MUST NOT attempt to apply the request to some other resource. If the server desires that the request be applied to a different URI,`}</p>
    </blockquote>
    <p>{`In other words, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`POST`}</code>{` is used to create/update a resource if you do not know the exact specified URI of where the new resource should locate.
For example, if you want to create a user, you may send a POST request to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/users/register`}</code>{` and the server will create user object based on the data in the request body.
That is because you don't know the exact URL to call for creating a user.
Remember, POST requests are non-idempotent, so making a POST request twice on the same URI would create two resources. `}</p>
    <p>{`On the other hand, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PUT`}</code>{` should be used to create/update to a resource to a particular URI that is known by client.
For example, PUT request to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/users/1/blog/2/comments/2`}</code>{` to modify a user's comment with id 2 on a blog post (assumed that 2 is the id in that URI).`}</p>
    <h2>{`DELETE method`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`DELETE`}</code>{` method will delete resource at a particular URI. It is safe and idempotent. `}</p>
    <h2>{`HEAD method`}</h2>
    <p>{`HEAD is pretty much the same as GET but with an exception that it does not contain the response body.
The common use is to check whether a GET request will return before actually sending one to the server.`}</p>
    <p>{`Example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`DELETE /file.html HTTP/1.1 `}</code></pre></div>
    <h2>{`PATCH method`}</h2>
    <p>{`PATCH requests are similar to PUT,
but it only applied partial modification to a resource.
This means that the request body should only contain a partial data to modify a
resource instead of a complete one. Besides, PATCH request is `}<em parentName="p">{`non-idempotent`}</em></p>
    <h2>{`OPTIONS method`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`OPTIONS`}</code>{` method shows the communication options for the target resource.`}</p>
    <p>{`Example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`OPTIONS /index.html HTTP/1.1
OPTIONS * HTTP/1.1`}</code></pre></div>
    <p>{`A response sample would contain `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`allow`}</code>{` header:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`HTTP/1.1 204 No Content
Allow: OPTIONS, GET, HEAD, POST
Cache-Control: max-age=604800
Date: Thu, 13 Oct 2016 11:45:00 GMT
Expires: Thu, 20 Oct 2016 11:45:00 GMT
Server: EOS (lax004/2813)
x-ec-custom-error: 1`}</code></pre></div>
    <p>{`(source: `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/OPTIONS"
      }}>{`https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/OPTIONS`}</a>{`)`}</p>
    <h2>{`CONNECT`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`CONNECT`}</code>{` method creates two-way communication with the resource. This is a less common method. It is not safe and idempotent.`}</p>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tools.ietf.org/html/rfc7231#section-4.3.4"
        }}>{`https://tools.ietf.org/html/rfc7231#section-4.3.4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/630453/put-vs-post-in-rest"
        }}>{`https://stackoverflow.com/questions/630453/put-vs-post-in-rest`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/Protocols/rfc2616/rfc2616-sec9.html"
        }}>{`https://www.w3.org/Protocols/rfc2616/rfc2616-sec9.html`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods"
        }}>{`https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      